import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Button from '@material-ui/core/Button'
import Hidden from '@material-ui/core/Hidden'
import Grid from '@material-ui/core/Grid'
import logo from '../../images/aginix-icon-header.png'
import ContactButton from '../ContactButton'

const styles = theme => {
  return {
    header: {
      background: theme.palette.common.white,
      borderBottom: `1px solid ${theme.palette.divider}`,
      zIndex: '10',
    },
    root: {
      flexGrow: 1,
    },
    img: {
      maxHeight: 36,
    },
    rightSideHeadBarContainer: {
      margin: '0 0 0 auto',
      display: 'flex',
      height: '64px',
      width: 'auto',
    },
    rightSideHeadBar: {
      margin: '0 10px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
    },
  }
}

function Header(props) {
  const { classes } = props

  return (
    <div className={classes.root}>
      <AppBar
        color="default"
        position="sticky"
        elevation={0}
        className={classes.header}
      >
        <Toolbar>
          <Link to="/">
            <img src={logo} alt="Aginix Technologies" className={classes.img} />
          </Link>
          <Grid container item className={classes.rightSideHeadBarContainer}>
            <Hidden only="xs">
              <Grid item className={classes.rightSideHeadBar}>
                <Link to="/">
                  <Button color="inherit">Home</Button>
                </Link>
              </Grid>
            </Hidden>
            <Grid item className={classes.rightSideHeadBar}>
              <ContactButton />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </div>
  )
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Header)
