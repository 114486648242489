import React from 'react'
import { Link } from 'gatsby'
import { Button } from '@material-ui/core'

const ContactButton = () => (
  <Link to="/contact">
    <Button variant="contained" color="primary">
      Contact Us
    </Button>
  </Link>
)

export default ContactButton
