import React from 'react'
import { ReactTypeformEmbed } from 'react-typeform-embed'
import { withStyles } from '@material-ui/core'

import Layout from '../components/Layout'
import SEO from '../components/seo'
import SectionHero from '../components/SectionHero/SectionHero'

const styles = () => {
  return {
    formContainer: {
      position: 'relative',
      maxWidth: '100%',
      background: '#ffffff',
      margin: '0 auto',
    },
    title: {
      color: 'rgba(0, 0, 0, 0.87)',
      margin: '32px 0 16px',
      fontSize: '3.75rem',
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 300,
      lineHeight: 1,
      letterSpacing: '-0.00833em',
    },
  }
}

const contactURL = 'https://aginix.typeform.com/to/Xn5nus'

const ContactUsPage = ({ classes }) => (
  <Layout>
    <SEO title="Contact | IT Solution & Development" />
    <SectionHero title="Contact Us" />
    <div className={classes.formContainer}>
      <ReactTypeformEmbed
        url={contactURL}
        style={{
          position: 'none',
          top: 0,
          left: 0,
          width: '100%',
          height: '580px',
        }}
      />
    </div>
  </Layout>
)

export default withStyles(styles)(ContactUsPage)
