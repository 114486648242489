import React from 'react'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => {
  const mediaMaxWidthSM =
    '@media (max-width: ' + theme.breakpoints.values.sm + 'px)'
  return {
    footer: {
      textAlign: 'center',
      margin: '18px',
      [mediaMaxWidthSM]: {
        fontSize: 11,
      },
    },
  }
}

const Footer = ({ classes }) => (
  <footer className={classes.footer}>
    {new Date().getFullYear()} © Aginix Technologies Co., Ltd. All Rights
    Reserved
  </footer>
)

export default withStyles(styles)(Footer)
